export default defineNuxtRouteMiddleware((to, from) => {
    if (process.server) {
        // Case #1
        //const headers = nuxtApp.ssrContext?.req.headers;

        // Case #2
        const headers = useRequestHeaders();
        const isValid = !!headers['x-landing'];

        if (!isValid) {
            return navigateTo('/');
        }
    }
})
